<script setup>
import {ref} from "vue";
import {useRouter} from "vue-router";
const router = useRouter()
const activeName = ref(1)
const active = ref(1)
const list3= ref([
  { id: 1,
    name: '后视镜',
    code: 'hsj-50',
    number: '20',
    chai: '10',
    sheng: '10',
    lot: 1
  },
  {  id: 2,
    name: '后视镜',
    code: 'hsj-50',
    number: '20',
    chai: '10',
    sheng: '10',
    lot: 1
  }
])
const list2= ref([
  {
    id: 1,
    name: '后视镜',
    code: 'hsj-50',
    number: '10',
    status: '有货',
    jig: '01',
    lot: 1
  },
  {
    id: 2,
    name: '后视镜',
    code: 'hsj-50',
    number: '10',
    status: '有货',
    jig: '01',
    lot: 1
  },
  {
    id: 3,
    name: '后视镜',
    code: 'hsj-50',
    number: '10',
    status: '有货',
    jig: '01',
    lot: 1
  },
])
function back() {
  router.push({ path: "/index" })
}
</script>

<template>
  <div>
    <van-nav-bar title="辅助作业" left-text="返回" left-arrow @click-left="back()" />
  </div>
  <div class="d_form">
    <van-form>
      <van-cell-group inset>
        <div class="flex scan">
          <div style="width: 90%;"><van-field v-model="dm" label="地码" /></div>
          <div><van-icon name="scan" size="22" /></div>
        </div>
        <van-field v-model="status1" label="库位状态" readonly />
        <div class="flex scan">
          <div style="width: 90%;"><van-field v-model="dm1" label="工装号" /></div>
          <div><van-icon name="scan" size="22" /></div>
        </div>
        <van-field v-model="jig_type" label="工装类型" readonly />
        <van-tabs v-model:active="active">
          <van-tab title="扫出">
            <div class="flex scan">
              <div style="width: 88%;"><van-field v-model="sc" label="直接扫出" /></div>
              <div><van-icon name="scan" size="22" /></div>
            </div>
            <div class="list2">
              <div class="table-container">
                <table class="wide-table">
                  <tr>
                    <th>序号</th>
                    <th>物料名称</th>
                    <th>物料编码</th>
                    <th>数量</th>
                    <th>E看板编码</th>
                    <th>供应商编码</th>
                    <th>批次</th>
                  </tr>
                  <tr v-for="user in list2" :key="user.id">
                    <td>{{ user.id }}</td>
                    <td>{{ user.name }}</td>
                    <td>{{ user.code }}</td>
                    <td>{{ user.number }}</td>
                    <td>{{ user.Ekanban }}</td>
                    <td>{{ user.providerCode }}</td>
                    <td>{{ user.lot }}</td>
                  </tr>
                </table>
              </div>
            </div>
            <div class="flex scan">
              <div style="width: 88%;"><van-field v-model="dm" label="拆分看板扫出" /></div>
              <div><van-icon name="scan" size="22" /></div>
            </div>
            <div class="list2">
              <div class="table-container">
                <table class="wide-table">
                  <tr>
                    <th>序号</th>
                    <th>物料名称</th>
                    <th>物料编码</th>
                    <th>数量</th>
                    <th>拆分</th>
                    <th>剩余</th>
                    <th>操作</th>
                  </tr>
                  <tr v-for="user in list3" :key="user.id">
                    <td>{{ user.id }}</td>
                    <td>{{ user.name }}</td>
                    <td>{{ user.code }}</td>
                    <td>{{ user.number }}</td>
                    <td>{{ user.chai }}</td>
                    <td>{{ user.sheng }}</td>
                    <td><van-button type="primary" size="mini">拆分</van-button><van-button type="primary" size="mini">打印</van-button></td>
                  </tr>
                </table>
              </div>
            </div>
          </van-tab>
          <van-tab title="移库">
            <div class="flex scan">
              <div style="width: 88%;"><van-field v-model="yk" label="直接移库" /></div>
              <div><van-icon name="scan" size="22" /></div>
            </div>
            <div class="flex scan">
              <div style="width: 88%;"><van-field v-model="yr" label="移入位置" /></div>
              <div><van-icon name="scan" size="22" /></div>
            </div>
            <div class="list2">
              <div class="table-container">
                <table class="wide-table">
                  <tr>
                    <th>序号</th>
                    <th>物料名称</th>
                    <th>物料编码</th>
                    <th>数量</th>
                    <th>E看板编码</th>
                    <th>供应商编码</th>
                    <th>批次</th>
                  </tr>
                  <tr v-for="user in list2" :key="user.id">
                    <td>{{ user.id }}</td>
                    <td>{{ user.name }}</td>
                    <td>{{ user.code }}</td>
                    <td>{{ user.number }}</td>
                    <td>{{ user.Ekanban }}</td>
                    <td>{{ user.providerCode }}</td>
                    <td>{{ user.lot }}</td>
                  </tr>
                </table>
              </div>
            </div>
            <div class="flex scan">
              <div style="width: 88%;"><van-field v-model="dm" label="拆分看板移库" /></div>
              <div><van-icon name="scan" size="22" /></div>
            </div>
            <div class="flex scan">
              <div style="width: 88%;"><van-field v-model="dm" label="移入位置" /></div>
              <div><van-icon name="scan" size="22" /></div>
            </div>
            <div class="list2">
              <div class="table-container">
                <table class="wide-table">
                  <tr>
                    <th>序号</th>
                    <th>物料名称</th>
                    <th>物料编码</th>
                    <th>数量</th>
                    <th>拆分</th>
                    <th>剩余</th>
                    <th>操作</th>
                  </tr>
                  <tr v-for="user in list3" :key="user.id">
                    <td>{{ user.id }}</td>
                    <td>{{ user.name }}</td>
                    <td>{{ user.code }}</td>
                    <td>{{ user.number }}</td>
                    <td>{{ user.chai }}</td>
                    <td>{{ user.sheng }}</td>
                    <td><van-button type="primary" size="mini">拆分</van-button><van-button type="primary" size="mini">打印</van-button></td>
                  </tr>
                </table>
              </div>
            </div>
          </van-tab>
          <van-tab title="扫入">
            <div class="flex scan">
              <div style="width: 88%;"><van-field v-model="sr" label="直接扫入" /></div>
              <div><van-icon name="scan" size="22" /></div>
            </div>
            <div class="list2">
              <div class="table-container">
                <table class="wide-table">
                  <tr>
                    <th>序号</th>
                    <th>物料名称</th>
                    <th>物料编码</th>
                    <th>数量</th>
                    <th>E看板编码</th>
                    <th>供应商编码</th>
                    <th>批次</th>
                  </tr>
                  <tr v-for="user in list2" :key="user.id">
                    <td>{{ user.id }}</td>
                    <td>{{ user.name }}</td>
                    <td>{{ user.code }}</td>
                    <td>{{ user.number }}</td>
                    <td>{{ user.Ekanban }}</td>
                    <td>{{ user.providerCode }}</td>
                    <td>{{ user.lot }}</td>
                  </tr>
                </table>
              </div>
            </div>
            <div class="list2">
              <div class="table-container">
                <table class="wide-table">
                  <tr>
                    <th>序号</th>
                    <th>物料名称</th>
                    <th>物料编码</th>
                    <th>数量</th>
                    <th>拆分</th>
                    <th>剩余</th>
                    <th>操作</th>
                  </tr>
                  <tr v-for="user in list3" :key="user.id">
                    <td>{{ user.id }}</td>
                    <td>{{ user.name }}</td>
                    <td>{{ user.code }}</td>
                    <td>{{ user.number }}</td>
                    <td>{{ user.chai }}</td>
                    <td>{{ user.sheng }}</td>
                    <td><van-button type="primary" size="mini">拆分</van-button><van-button type="primary" size="mini">打印</van-button></td>
                  </tr>
                </table>
              </div>
            </div>
          </van-tab>
          <van-tab title="合并">
            <van-collapse v-model="activeName" accordion>
              <van-collapse-item title="物料" name="1">
                <div class="table-container">
                  <table class="wide-table">
                    <tr>
                      <th>选择</th>
                      <th>序号</th>
                      <th>物料名称</th>
                      <th>物料编码</th>
                      <th>数量</th>
                      <th>状态</th>
                      <th>批次</th>
                    </tr>
                    <tr v-for="user in list2" :key="user.id">
                      <td><van-checkbox v-model="user.check" icon-size="20px"></van-checkbox></td>
                      <td>{{ user.id }}</td>
                      <td>{{ user.name }}</td>
                      <td>{{ user.code }}</td>
                      <td>{{ user.number }}</td>
                      <td>{{ user.status }}</td>
                      <td>{{ user.lot }}</td>
                    </tr>
                  </table>
                </div>
              </van-collapse-item>
            </van-collapse>
          </van-tab>
        </van-tabs>
      </van-cell-group>
      <div style="margin: 16px;">
        <van-button block type="primary" size="small" native-type="submit">
          确定
        </van-button>
      </div>
      <div style="height: 80px"></div>
    </van-form>
  </div>
</template>

<style scoped>
.flex{
  display: flex;
}
.scan{
  align-items: center;
}
.kan{
  justify-content: space-between;
  align-items: center;
}

</style>
